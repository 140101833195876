import React from 'react'
import icons from '../../../../../assets/icons';
import { BEHANCE, FACEBOOK, INSTAGRAM, LINKEDIN, PINTEREST, SKYPE, SNAPCHAT, TELEGRAM, TIKTOK, TWITTER, VIBER, WECHAT, YOUTUBE } from '../../../../../lib/constants/socialmediatypes';
const SocialLink = ({index,social}:{index:number,social:any}) => {
  let iconSrc = '';
  let link = social.url;

  switch(social.category) {
    case FACEBOOK:
      iconSrc = icons.facebook_landingpage_icon;
      break;
    case INSTAGRAM:
      iconSrc = icons.instagram_landingpage_icon;
      break;
    case LINKEDIN:
      iconSrc = icons.linkedin_landingpage_icon;
      break;
    case TIKTOK:
      iconSrc = icons.tiktok_landingpage_icon;
      break;
    case SNAPCHAT:
      iconSrc = icons.snapchat_landingpage_icon;
      break;
    case SKYPE:
      iconSrc = icons.skype_landingpage_icon;
      break;
    case BEHANCE:
      iconSrc = icons.behance_landingpage_icon;
      break;
    case YOUTUBE:
      iconSrc = icons.youtube_landingpage_icon;
      break;
    case PINTEREST:
      iconSrc = icons.pinterest_landingpage_icon;
      break;
    case TELEGRAM:
      iconSrc = icons.telegram_landingpage_icon;
      break;
    case WECHAT:
      iconSrc = icons.wechat_landingpage_icon;
      link = `weixin://weixin.qq.com/r/${link}`
      break;
    case TWITTER:
      iconSrc = icons.x_landingpage_icon
      break;
    case VIBER:
      iconSrc = icons.viber_landingpage_icon
      break;
    default :
    iconSrc = icons.webpage_landingpage_icon;
      // code block
  } 
  return (
  <img key={index} src={iconSrc} alt={"sociallink"+index} style={{
    marginRight:"0.5rem",
    width: '38px',
    height: '38px'
  }}
  onClick={() => window.open(link)}/>
  )
}

export default SocialLink