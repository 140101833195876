import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Box, Chip, Tab, Tabs, Typography, Link } from '@mui/material';
import icons from '../../../../assets/icons';
import SocialLink from './component/socialLink';
import DocumentAttachment from './component/documentAttachment';
import EditDialog from './component/editDialog';
import moment from 'moment';

const ContactUserBox = styled(Box)({
  display: 'flex',
  gap: '1.5rem',
  padding: '1.5rem',
  alignItems: 'center',
});

const ContactDetailBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '3fr 1fr',
  gap: '1.5rem',
  padding: '1.5rem',
  height: '450px'
});

const ContactDetailCard = styled(Box)({
  borderRadius: 8,
  border: '1px solid #E9E9E9',
  background: '#FBFCFF',
  padding: '16px 24px',
});

const ContactPersonalDetailBox = styled(Box)({
  display: 'grid',
  justifyItems: 'start',
  gridTemplateColumns: '1fr 1fr',
  paddingTop: '1rem',
  '& .MuiTypography-root': {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%'
  },
})

const ProfileImage = styled(Box)({
  borderRadius: "79.5px",
  width: "79.5px",
  height: "79.5px",
  flexShrink: 0,
  background: "lightgray 50% / cover no-repeat",
  backgroundImage: `url(${icons.profile_picture_icon})`,
  backgroundSize: 'cover',
  backgroundRepeatX: 'no-repeat',
  backgroundRepeatY: 'no-repeat',
});

const ContactDetailComponent = ({ contacts, profileImage, contactUUID, viewProfile, handleSaveContact }: {
  contacts: any,
  profileImage: string,
  contactUUID: string,
  viewProfile: (value: string) => void,
  handleSaveContact: any
}) => {
  const [value, setValue] = useState(0);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateKey, setUpdateKey] = useState(0); // This is used to force a re-render of the component when the contacts array changes
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const handleEditDialog = () => {
    setOpenEditDialog(!openEditDialog)
  }

  useEffect(() => {
    setUpdateKey(updateKey + 1) //force update component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImage]);

  return (
    <>
      {contacts[0]?.type === "guest" &&
        <EditDialog open={openEditDialog} handleClose={handleEditDialog} contact={contacts[0]} contactUuid={contactUUID}></EditDialog>
      }
      <Tabs value={value} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Details" />
      </Tabs>
      <ContactUserBox>
        {
          profileImage === "" ?
            <ProfileImage></ProfileImage> :
            <ProfileImage key={updateKey} sx={{ backgroundImage: `url(${profileImage})` }}></ProfileImage>
        }

        <Box sx={{
          flex: 1
          // width:'-webkit-fill-available'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography component={'span'} sx={{ fontSize: 25, fontStyle: 'normal', fontWeight: 700 }}>
              {contacts.length > 0 && contacts[0].name}
            </Typography>
            {contacts.length > 0 && contacts[0].type === "member" ?
              <Chip size="small" label="MEMBER" color="success" /> :
              <Chip size="small" label="GUEST" color="secondary" />
            }
          </Box>
          <Typography sx={{ fontSize: 16, fontStyle: 'normal', fontWeight: 400, color: 'var(--Text-Low, #A7A7A7)' }}>
            {contacts.length > 0 && contacts[0].jobTitle}
          </Typography>
          <Typography sx={{ fontSize: 16, fontStyle: 'normal', fontWeight: 400, color: 'var(--Text-Low, #A7A7A7)' }}>
            Date of birth : {contacts.length > 0 && contacts[0].birthday}
          </Typography>
          <Typography sx={{ fontSize: 16, fontStyle: 'normal', fontWeight: 400, color: 'var(--Text-Low, #A7A7A7)' }}>
            Date of join : {contacts.length > 0 && (contacts[0].createdAt !== null ? moment(contacts[0].createdAt).format('DD-MM-YYYY') : "")}
          </Typography>

        </Box>
        {contacts.length > 0 && contacts[0].type === "guest" &&
          <div>
            <Button variant='contained' sx={{ borderRadius: '30px', marginRight: '10px' }}
              onClick={() => handleSaveContact()}> Save Contacts</Button>
            <Button variant='contained' sx={{ borderRadius: '30px' }}
              onClick={() => handleEditDialog()}> Edit Details</Button>
          </div>

        }
      </ContactUserBox>
      {contacts.length > 0 &&
        contacts.map((contact, index) => {
          return (
            <ContactDetailBox key={index}>
              <ContactDetailCard>
                <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ fontSize: 25, fontStyle: 'normal', fontWeight: 500 }}>
                    {contact.name}
                  </h2>
                  {contact.type === "member" &&
                    <Button
                      onClick={() => viewProfile(contact.serialNumber)}
                      variant='contained'
                      sx={{ borderRadius: '30px', height: '40px', color: 'black', backgroundColor: '#FECD20' }}>View Profile</Button>
                  }
                </Box>
                <Box>
                  <Typography sx={{
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    borderBottom: '1px solid #D5D5D5',
                  }}> Personal Details</Typography>
                </Box>
                <ContactPersonalDetailBox>
                  {/* EMAIL */}
                  <Typography> Email: </Typography>
                  {
                    contact.email.map((em) => {
                      return <Link href={`mailto:${em}`} sx={{ color: '#3AB3F7', textDecoration: 'none', gridColumn: 2 }}>
                        <Typography sx={{ fontSize: 13, fontWeight: 400, textAlign: 'start' }}>
                          {em}
                        </Typography>
                      </Link>
                    })
                  }

                  {/* <Typography> {contact.email}</Typography> */}
                </ContactPersonalDetailBox>
                {/* JOB TITLE */}
                <ContactPersonalDetailBox>
                  <Typography> Job Title: </Typography>
                  <Typography> {contact.jobTitle}</Typography>
                </ContactPersonalDetailBox>
                <ContactPersonalDetailBox>
                  {/* COMPANY NAME */}
                  <Typography> Company Name: </Typography>
                  <Typography> {contact.companyName}</Typography>
                </ContactPersonalDetailBox>
                {/* COMPANY ADDRESS */}
                <ContactPersonalDetailBox>
                  <Typography> Company Address: </Typography>
                  <Typography > {contact.companyAddress}</Typography>
                </ContactPersonalDetailBox>
                {/* WEBSITE */}
                <ContactPersonalDetailBox>
                  <Typography> Website: </Typography>
                  {
                    contact.website.map((em) => {
                      return <Link href={`${em}`} sx={{ color: '#3AB3F7', textDecoration: 'none', gridColumn: 2 }}>
                        <Typography sx={{ fontSize: 13, fontWeight: 400, textAlign: 'start' }}>
                          {em}
                        </Typography>
                      </Link>
                    })
                  }
                  {/* <Typography > {contact.Website}</Typography> */}
                </ContactPersonalDetailBox>
                <ContactPersonalDetailBox>
                  {/* PHONE NUMBER */}
                  <Typography> Phone Number: </Typography>
                  {
                    contact.phoneNumber.map((em) => {
                      return <Link href={`tel:${em}`} sx={{ color: '#3AB3F7', textDecoration: 'none', gridColumn: 2 }}>
                        <Typography sx={{ fontSize: 13, fontWeight: 400, textAlign: 'start' }}>
                          {em}
                        </Typography>
                      </Link>
                    })
                  }
                </ContactPersonalDetailBox>

              </ContactDetailCard>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', justifyContent: 'flex-start' }}>
                {contact.type === "member" &&
                  <>
                    {contact.files?.length > 0 &&
                      <ContactDetailCard>
                        <Box sx={{ paddingBottom: '1rem' }}>
                          <Typography>Download Documents</Typography>
                        </Box>
                        {contact.files.map((file, index) => {
                          return (
                            <DocumentAttachment key={index} index={index} file={file} />
                          )
                        })
                        }

                      </ContactDetailCard>
                    }

                    {contact.socialInfo?.length > 0 &&
                      <ContactDetailCard>
                        <Box>
                          <Typography>Social Link</Typography>
                        </Box>
                        <Box sx={{ paddingTop: "1rem" }}>
                          {contact.socialInfo.map((social, index) => {
                            return (
                              <SocialLink key={index} index={index} social={social} />
                            )
                          })
                          }
                        </Box>
                      </ContactDetailCard>
                    }
                  </>
                }
              </Box>
            </ContactDetailBox>
          )
        }
        )}
    </>
  );
};

export default ContactDetailComponent;
