import React, { useState } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Collapse, IconButton, Paper, Typography } from '@mui/material';
import ExpandMore from '../component/expandMore.component';
import icons from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { VOUCHER_DETAIL } from '../../../lib/constants/routes';
import { VoucherType } from '../../../api/basicCampaign/getVoucherListByProfile.api';
import moment from 'moment';

interface VouchersComponentProps {
  vouchers: VoucherType[];
  isPreview?: boolean;
}

export default function VouchersComponent({ vouchers, isPreview = false }: VouchersComponentProps) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState('vouchers');

  return <Paper sx={{ m: '10px 14px', p: '5px', borderRadius: '10px' }}>
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ margin: '3px 6px', fontSize: '17px', fontWeight: 700, flex: 1 }}>
        Vouchers
      </Typography>
      <ExpandMore
        expanded={expanded === 'vouchers'}
        onClick={() => setExpanded(expanded === 'vouchers' ? '' : 'vouchers')}
        aria-expanded={expanded === 'vouchers'}
        aria-label="show more"
      />
    </div>

    <Collapse sx={{ p: 0, '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'column', gap: '10px' } }} in={expanded === 'vouchers'} timeout="auto" unmountOnExit>
      {vouchers.map((vc, index) => (
        <Card key={index} elevation={0} sx={{ border: '0.5px solid #C5C5C5', borderRadius: '10px' }}>
          <CardActionArea onClick={isPreview ? console.log : () => navigate({ pathname: `${VOUCHER_DETAIL}`.replace(':voucherUUID', vc.voucher_uuid) })}>
            <CardMedia
              sx={{ height: 140 }}
              image={vc.banner_image}
              title="voucher banner"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {vc.voucher_title}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ flex: 1 }} variant="body2" color="text.secondary">
                  Valid till {moment(`${vc.end_date_time}`, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY, h:mm A')}
                </Typography>
                <IconButton>
                  <img src={icons.arrow_right_icon} alt='' />
                </IconButton>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Collapse>
  </Paper>
}