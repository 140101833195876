import React, { useState } from "react"
import { Box, Button, Typography } from "@mui/material";
import images from "../../../../assets/img";
import PlanDialog from "./planDialog";

export default function SubscriptionTabComponent({ handleViewPricing, handleBuyCard, accessLimit, tier, toggleStatus }) {
  const [open, setOpen] = useState(false);

  return <>
    <PlanDialog
      open={open}
      handleClose={() => setOpen(false)}
      currency='USD'
      tier={tier}
      period='monthly'
    />
    <Typography sx={{ fontSize: '31px', fontWeight: 400 }} gutterBottom>Subscription Plan</Typography>
    <Box
      key={tier.id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        height: '198px',
        background: tier.background,
        p: '20px',
      }}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ flex: 1, color: 'white', fontSize: '25px', fontWeight: 700 }}>
          {accessLimit.subscription_type}
        </Typography>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography
          component='span'
          sx={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => setOpen(true)}
        >
          Read More
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ flex: 1, color: 'white' }}>
          {tier?.type !== 'FREE_TIER' && `Expiry Date: ${accessLimit.end_date}`} {tier?.type !== 'FREE_TIER' && ((Number(accessLimit.selected_subscriptions_id) === 1 || toggleStatus) ? '(Auto-renew OFF)' : '(Auto-renew ON)')}
        </Typography>
        <Button variant="contained" color="secondary" onClick={handleViewPricing}>
          {tier?.type !== 'FREE_TIER' ? (Number(accessLimit.selected_subscriptions_id) === 1 || toggleStatus) ? 'Subscribe' : 'Unsubscribe' : 'Upgrade Plan'}
        </Button>
      </Box>
    </Box>
    <Box sx={{
      mt: '20px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      borderRadius: '8px',
      background: `no-repeat linear-gradient(135deg, #AFE4D0, #BAB0ED 50%)`,
    }}>
      <Box sx={{ flex: 1, m: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', minWidth: '385px' }}>
        <Typography sx={{ color: '#232322', fontSize: '25px' }}>
          Want to apply NFC card?
        </Typography>
        <Typography sx={{ flex: 1, color: '#232322', fontSize: '13px' }}>
          Start customizing your business card to attract your target audience. Make life more creative!
        </Typography>
        <Button variant="contained" color="primary" onClick={handleBuyCard}>Buy Card</Button>
      </Box>
      <img
        src={images.buycard_banner}
        alt=""
        style={{
          flex: 1,
          marginLeft: 'auto',
          marginRight: 'auto',
          objectFit: 'cover',
          objectPosition: '0 0',
          maxWidth: '370px',
          maxHeight: '200px'
        }}
      />
    </Box>
  </>
}