import React ,{useState,useRef} from 'react';
import { GoogleMap as Map, useJsApiLoader } from '@react-google-maps/api';
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import icons from 'src/assets/icons';
import { Box, Typography, Paper} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from "@mui/system";
import './google-map.css';
function GoogleMap({
  contacts = [],
  profileViews = [],
  googleMapIndex,
  totalCountryPercentage = [],
  totalStatePercentage = [],
  totalCityPercentage = [],
}) {
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  const [map] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [center, setCenter] = useState({
    lat: 3.139,
    lng: 101.6869,
  });
  const markerRef = useRef([]);
  const markerClusterRef = useRef(null);
  const initialZoomRef = useRef(5);
  const hasInitialZoomRef = useRef(false);
  const initialCenterRef = useRef({
    lat: 3.139,
    lng: 101.6869,
  });


  const onLoad = React.useCallback(function callback(map) {
    map.setOptions({
      mapId: "898db239baad3210",
      styles: [
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    });
    map.setZoom(5.0);
    if(contacts.length>0 || profileViews.length>0) initMap(map);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts, profileViews]);


  async function initMap(map) {
    const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
      "marker"
    );
    let markers =[];

    contacts.map((contact, index) => {
      const mark = new AdvancedMarkerElement({
        map,
        content: buildContactMarker(contact),
        position: {
          lat: parseFloat(contact.latitude),
          lng: parseFloat(contact.longitude),
        },
      });
      mark.id = `${contact.contact_id}_contact`;
      markerRef.current.push(mark);

      markers.push(mark);
 
      return true;
    });

    profileViews.map((profile, index) => {
      const mark = new AdvancedMarkerElement({
        map,
        content: buildProfileMarker(profile),
        position: {
          lat: parseFloat(profile.latitude),
          lng: parseFloat(profile.longitude),
        },
      });
      mark.id = `${profile.id}_profile`;
      markerRef.current.push(mark);
      markers.push(mark);
      return true;
    })

    if (markerClusterRef.current) {
      markerClusterRef.current.clearMarkers(false);
    }

    markerClusterRef.current = new MarkerClusterer({
      map,
      markers,
      onClusterClick: function onClusterClick(event, cluster, map) {
      },
      algorithm: new SuperClusterAlgorithm({
        radius: 160,
        maxZoom: 30,
      }),
      renderer: {
        render: (cluster, stats) => {
          let result = { contact: 0, profile: 0, markerId: [] };
          cluster.markers.map(
            (value) => {
              let infoData = value.id.split("_");
              result.markerId.push(infoData[0]);

              if (infoData[1] === "contact") {
                result.contact += 1;
              } else {
                result.profile += 1;
              }

              return true;
            },
            { contact: 0, profile: 0, markerId: [] }
          );

          return new AdvancedMarkerElement({
            map,
            content: buildClusterContent(cluster, result),
            position: cluster.position,
          });
        },
      },
    });
  }

  const buildContactMarker = () => {
    const content = document.createElement("div");

    content.innerHTML = `
      <img src="${icons.marker_contact}" />
    `;

    return content;
  };

  const buildProfileMarker = () => {
    const content = document.createElement("div");

    content.innerHTML = `
      <img src="${icons.marker_profile}" />
    `;

    return content;
  };

  const buildClusterContent = (cluster, result) => {
    const content = document.createElement("div");
    let status = "profile";
    
    if (result.profile && !result.contact) {
      status = "profile";
    } 
    if (result.contact && !result.profile) {
      status = "contact";
    }

    let total = result.contact + result.profile;
    let ratio = result.profile / total;

    if(result.contact > 0 && result.profile <= 0) {
    content.innerHTML = `
    <svg width="49" height="67" viewBox="0 0 49 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.989" fill-rule="evenodd" clip-rule="evenodd" d="M16.5837 18C17.8478 18 19.1117 18 20.3758 18C27.4738 18.9854 32.5465 22.5902 35.5941 28.8145C37.0362 32.26 37.369 35.801 36.592 39.4375C35.5893 43.2472 34.0592 46.852 32.0016 50.252C28.7413 55.6112 25.0156 60.6516 20.8249 65.373C20.3192 66.0017 19.7371 66.544 19.0785 67C18.6793 67 18.2802 67 17.881 67C17.2224 66.544 16.6403 66.0017 16.1347 65.373C11.9439 60.6516 8.21827 55.6112 4.95797 50.252C2.90035 46.852 1.37021 43.2472 0.367549 39.4375C-0.923996 32.4606 1.18827 26.6386 6.70433 21.9717C9.60418 19.7518 12.8973 18.4279 16.5837 18ZM17.2823 26.9004C21.3692 26.6508 24.4793 28.182 26.6128 31.4941C28.5614 35.5482 27.9128 39.169 24.6669 42.3564C21.3704 44.912 17.8112 45.2948 13.9891 43.5049C10.2706 41.2359 8.75705 37.9979 9.4486 33.791C10.5854 29.9409 13.1967 27.644 17.2823 26.9004Z" fill="#FF800C"/>
    <ellipse cx="29" cy="18.5" rx="18" ry="18.5" fill="#FECD20"/>
    <text x="29" y="22" fill="black" font-size="14" font-weight="600" font-family="Arial" text-anchor="middle">${result.contact}</text>
    </svg>
     
        `;
      } 
      else if(result.profile > 0 && result.contact <= 0) {
        content.innerHTML = `
        <svg width="47" height="67" viewBox="0 0 47 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.989" fill-rule="evenodd" clip-rule="evenodd" d="M15.6873 18C16.883 18 18.0787 18 19.2744 18C25.9887 18.9854 30.7872 22.5902 33.6701 28.8145C35.0343 32.26 35.349 35.801 34.614 39.4375C33.6655 43.2472 32.2181 46.852 30.2717 50.252C27.1877 55.6112 23.6634 60.6516 19.6992 65.373C19.2209 66.0017 18.6703 66.544 18.0472 67C17.6697 67 17.2921 67 16.9145 67C16.2914 66.544 15.7408 66.0017 15.2625 65.373C11.2983 60.6516 7.77404 55.6112 4.68997 50.252C2.74357 46.852 1.29614 43.2472 0.347682 39.4375C-0.87405 32.4606 1.12404 26.6386 6.34193 21.9717C9.08503 19.7518 12.2002 18.4279 15.6873 18ZM16.3481 26.9004C20.2141 26.6508 23.1561 28.182 25.1743 31.4941C27.0176 35.5482 26.404 39.169 23.3335 42.3564C20.2153 44.912 16.8484 45.2948 13.233 43.5049C9.71542 41.2359 8.28369 37.9979 8.93787 33.791C10.0132 29.9409 12.4833 27.644 16.3481 26.9004Z" fill="#6644EC"/>
        <ellipse cx="29" cy="18.5" rx="18" ry="18.5" fill="#FECD20"/>
        <text x="29" y="22" fill="black" font-size="14" font-weight="600" font-family="Arial" text-anchor="middle">${result.profile}</text>
        </svg>
        
        `;
      }
      else {
        content.innerHTML = `
        <div class="clusterSection">
        <div class="${status}Cluster" style="background-image:conic-gradient(#6644EC ${
          360 * ratio
        }deg, #FF800C ${360 * ratio}deg)">
              <span>${total}</span>
            </div>
          </div>
        `;
      }

    return content;
  };

  const updateZoomLevel = () => {
    if (map) {
      let tempZoom = map.getZoom();
      if (!hasInitialZoomRef.current) {
        tempZoom = tempZoom > 15 ? 15 : tempZoom;
        initialZoomRef.current = tempZoom > 15 ? 15 : tempZoom;

        let tempCenter = map.getCenter();
        initialCenterRef.current = {
          lat: tempCenter.lat(),
          lng: tempCenter.lng(),
        };
        hasInitialZoomRef.current = true;
      }
      setCenter(initialCenterRef.current);
      setZoomLevel(tempZoom);
    }
  }

  const RatioClusterMarker = styled(Box)({
    padding: "0px 11px",
    backgroundColor: "white",
    position: "absolute",
    bottom: 13,
    right: "10%",
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    boxShadow: "1px 1px 3px 1px #00000021",
  })

  const ProfileClusterMarker = styled(Box)({
    height: 8,
    width: 8,
    borderRadius: "50%",
    backgroundColor: "#F97066",
    marginRight: 8,
  })

  const ContactClusterMarker = styled(Box)({
    height: 8,
    width: 8,
    borderRadius: "50%",
    backgroundColor: "#32D583",
    marginRight: 8,
  })
  return (
    <>
    {/* <ContactDialog
      open={contactOpen}
      handleClose={handleContactClose}
      contactUUID={contactUUID}
    /> */}
    {/* <VoucherDialog
      open={voucherOpen}
      handleClose={() => setVoucherOpen(false)}
      currentVoucher={currentVoucher}
      voucherUUID={voucherUUID}
    />   */}
       <Paper 
       className="container"
       sx={{
        mt: 3,
        p: '1rem' 
       }}>
      {!isLoaded && <div>Loading...</div>}
      {isLoaded &&(<><Box sx={{
        position: "relative",
      }}>
     
      <Map
        key={googleMapIndex}
        mapContainerStyle={{
          width: "100%",
          height: "560px",
        }}
        onLoad={onLoad}
        // onUnmount={onUnmount}
        onZoomChanged={updateZoomLevel}
        center={center}
        zoom={zoomLevel}
        options={{
          mapTypeControl: true,
          streetViewControl: false,
          rotateControl: true,
          // fullscreenControl: true,
          // gestureHandling: "none",
          clickableIcons: false,
          keyboardShortcuts: false,
        }}
      ></Map>
        <RatioClusterMarker>
          <Box display="flex" alignItems="center" mr={2}>
            <ProfileClusterMarker/>
            <Typography variant="caption">
              Contact Exchanged
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <ContactClusterMarker/>
            <Typography variant="caption">
              Profile Viewed
            </Typography>
          </Box>
        </RatioClusterMarker>
      <Box sx={{
            padding: "0px 11px",
            backgroundColor: "white",
            position: "absolute",
            bottom: 13,
            right: "10%",
            display: "flex",
            alignItems: "center",
            borderRadius: 4,
            boxShadow: "1px 1px 3px 1px #00000021"
      }}>
          <Box display="flex" alignItems="center" mr={2}>
            <Box sx={{
                  height: 8,
                  width: 8,
                  borderRadius: "50%",
                  backgroundColor: "#FF800A",
                  marginRight: 1,
            }} />
            <Typography variant="caption">
              Contacts Exchanged
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box sx={{
                  height: 8,
                  width: 8,
                  borderRadius: "50%",
                  backgroundColor: "#6744EC",
                  marginRight:1,
            }} />
            <Typography variant="caption">
              Profile Viewed
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display:"grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: 4,
        padding:2
      }}>
        <Box sx={{
          display:"flex",
          flexDirection: "column",
          gap: 2
        }}>
        <Typography sx={{
          fontWeight: "600"
        }} >
          Countries - Top 10
        </Typography>
        {
          totalCountryPercentage.length > 0 ? 
            totalCountryPercentage.slice(0, 10).map((value, index) => {
              return (
                <Box key={index}>
                <Box sx={{
                  display:"flex",
                  justifyContent: "space-between",
                }}>
      
                  <Typography sx={{
                    fontSize:'12px'
                  }}>
                    {value.name}
                  </Typography>
                  <Typography sx={{
                    fontSize:'12px'
                  }}>
                    {value.count}&nbsp;({value.percentage}%)
                  </Typography>
                </Box>
                <LinearProgress variant="determinate" sx={{
                  "& .MuiLinearProgress-root": {
                    height: '10px',
                    borderRadius: '47px',
                  }
                }}value={value.percentage} />
              </Box>
              )
            }) : <Typography>No data found</Typography>
        }

        </Box>
        <Box sx={{
          display:"flex",
          flexDirection: "column",
          gap: 2
        }}>
          <Typography sx={{
            fontWeight: "600"
          }}>
            States(Country) - Top 10
          </Typography>
          {
            totalStatePercentage.length > 0 ? 
            totalStatePercentage.slice(0, 10).map((value, index) => {
              return (
                <Box>
                <Box sx={{
                  display:"flex",
                  justifyContent: "space-between",
                }}>
                  <Typography sx={{
                    fontSize:'12px'
                  }}>
                    {value.name} ({value.country}) 
                  </Typography>
                  <Typography sx={{
                    fontSize:'12px'
                  }}>
                    {value.count}&nbsp;({value.percentage}%)
                  </Typography>
                </Box>
                <LinearProgress variant="determinate" sx={{
                  "& .MuiLinearProgress-root": {
                    height: '10px',
                    borderRadius: '47px',
                  }
                }}value={value.percentage} />
              </Box>
              )
            }) : <Typography>No data found</Typography>
          }
        </Box>

        <Box sx={{
          display:"flex",
          flexDirection: "column",
          gap: 2
        }}>
        <Typography sx={{
          fontWeight: "600"
        }}>
        Cities(State) - Top 10
        </Typography>
        {
            totalCityPercentage.length > 0 ? 
            totalCityPercentage.slice(0, 10).map((value, index) => {
              return (
                <Box>
                <Box sx={{
                  display:"flex",
                  justifyContent: "space-between",
                }}>
                  <Typography sx={{
                    fontSize:'12px'
                  }}>
                    {value.name} ({value.country}) 
                  </Typography>
                  <Typography sx={{
                    fontSize:'12px'
                  }}>
                    {value.count}&nbsp;({value.percentage}%)
                  </Typography>
                </Box>
                <LinearProgress variant="determinate" sx={{
                  "& .MuiLinearProgress-root": {
                    height: '10px',
                    borderRadius: '47px',
                  }
                }}value={value.percentage} />
              </Box>
              )
            }) : <Typography>No data found</Typography>
          }
        </Box>

      </Box>
      </>
      )
    }
    </Paper>
    </>
  );
}

export default GoogleMap;
