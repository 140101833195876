import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import accountApi from '../../../../api/account';
import { useDispatch, useSelector } from 'react-redux'
import { openActionDialog } from '../../../../features/action-dialog'
import { openResponseDialog } from '../../../../features/response-dialog'
import { EXCHANGE_CONTACT_PERSONAL_INFO, FORGOT_PASSWORD, PORTAL, SIGN_UP, UNVERIFIED } from "../../../../lib/constants/routes.js";
import productApi from '../../../../api/product/index.js';
import { clearItems, setItems } from '../../../../features/cart/cart.slice.js';
import { v4 as uuidv4 } from 'uuid';
import SignInComponent from './signIn.component.tsx';
import { endLoading, startLoading } from '../../../../features/loading/index.js';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SignIn() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const [isLoading, setIsLoading] = React.useState(false);
  const actionDialog = useSelector((state: any) => state.actionDialog)
  const baseUrl = process.env.REACT_APP_API_HOST_URL;

  async function postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    if (response.ok) {
      return response.json();
    }

    dispatch(openResponseDialog({
      title: 'Credentials Incorrect',
      description: "Please check your email and password. If needed, click 'Forgot Password' to reset."
    }))
    return null;
  }

  // Function to call after successful login
  const postLogin = (d: any, is_social = false) => {
    if (d === null) return;

    if (!d.success) {
      dispatch(openResponseDialog({
        title: 'Credentials Incorrect',
        description: "Please check your email and password. If needed, click 'Forgot Password' to reset."
      }));
    }
    localStorage.setItem("user", JSON.stringify(d.data));

    const verified = is_social ? d.data.access_token_payload.social_info_verified : d.data.access_token_payload.verified;

    if (Boolean(verified)) {
      productApi.getShoppingCartListing()
        .then(response => {
          if (response.data?.cart === undefined) return;
          const items = response.data?.cart.map(item => ({
            ...item,
            // product_uuid: item.product_uuid,
            // cart_uuid: item.cart_uuid,
            name: item.card_name,
            // price: item.price,
            // quantity: item.quantity,
            designid: item.design_id,
            variant: {
              name: item.card_variation,
              front: item.front_thumbnail,
              back: item.back_thumbnail,
            },
            fontColor: item.font_color_code,
            logoUrl: item.logo_url,
            personName: item.name,
            jobTitle: item.position,
            // customization: item.customization,
            customizationPrice: item.customization_price,
            customizationDiscountPrice: item.customization_discount_price,
            pixelcare: item.pixelcare === 1,
            pixelcarePrice: item.pixelcare_price,
            pixelcareDiscountPrice: item.pixelcare_discount_price,
            totalCustomizationPrice: item.total_customization_price,
            totalPixelcarePrice: item.total_pixelcare_price,
          }));
          if (Boolean(items.length)) {
            dispatch(setItems(items));
          } else {
            dispatch(clearItems());
          }
        });

      if (Boolean(state)) {
        navigate({
          pathname: state?.path,
          search: state?.search,
        });
      } else {
        navigate(PORTAL);
      }
    } else {
      if (is_social) {
        navigate(EXCHANGE_CONTACT_PERSONAL_INFO);
      } else {
        accountApi.resendEmailVerification({ email: d.data.access_token_payload.email });
        navigate(UNVERIFIED);
      }
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    const body = {
      email: data.get('email'),
      password: data.get('password'),
      remember_me: data.get('remember') === 'remember',
    };

    postData(baseUrl + "/basicauth/signin", body)
      .then(postLogin)
      .catch(error => error)
      .finally(() => setIsLoading(false));
    return false;
  };

  const googleLogin = useGoogleLogin({
    onSuccess: function (codeResponse: any) {
      const body = {
        social_signin_type: 'google',
        social_signin_access_token: codeResponse.access_token,
      };

      postData(baseUrl + "/basicauth/socialSignIn", body)
        .then(res => postLogin(res, true))
        .catch(console.log)
        .finally(() => setIsLoading(false));
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  const handleFacebookLogin = (response) => {
    const body = {
      social_signin_type: 'facebook',
      social_signin_access_token: response.accessToken,
    };

    postData(baseUrl + "/basicauth/socialSignIn", body)
      .then(res => postLogin(res, true))
      .catch(console.log)
      .finally(() => setIsLoading(false));
  }

  const dispatch = useDispatch()
  const handleContactUs = () => {
    dispatch(openActionDialog({ title: "We're Here to Help", description: `Let us know the details, and we’ll get back to you promptly. Reach us at ${process.env.REACT_APP_SUPPORT_EMAIL} or call ${process.env.REACT_APP_SUPPORT_CONTACT}.` }))
  }

  const handleLineLogin = () => {
    dispatch(openActionDialog({
      title: "Login with LINE",
      description: "We will collect the email address you used to register with LINE. This is to associate you with the account previously registered with the same email address, if any.",
      confirmText: 'Proceed',
      action: 'initiateLineLogin',
    }));
  }

  const initiateLineLogin = () => {
    const channel_id = process.env.REACT_APP_LINE_CLIENT_ID;
    const redirect_uri = encodeURIComponent(window.location.origin + window.location.pathname);
    const state = uuidv4();
    const nonce = uuidv4();
    const scope = 'profile%20openid%20email';
    const login_url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${channel_id}&redirect_uri=${redirect_uri}&state=${state}&scope=${scope}&nonce=${nonce}`;
    window.location.href = login_url;
  }

  const lineLogin = (code: string) => {
    const body = {
      social_signin_type: 'line',
      social_signin_access_token: code,
    };

    postData(`${baseUrl}/basicauth/socialSignIn`, body)
      .then(res => postLogin(res, true))
      .catch(console.log)
      .finally(() => dispatch(endLoading()));
  }

  useEffect(() => {
    if (actionDialog.isConfirmed && actionDialog.action === "initiateLineLogin") initiateLineLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDialog])

  useEffect(() => {
    const line_code = query.get('code');

    if (Boolean(line_code)) {
      dispatch(startLoading());
      lineLogin(`${line_code}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAppleLogin = () => {
    const searchParams = new URLSearchParams({
      response_type: "code",
      response_mode: "form_post",
      redirect_uri: encodeURIComponent(`${window.location.origin}${window.location.pathname}&type=apple`),
      client_id: `${process.env.REACT_APP_APPLE_CLIENT_ID}`,
      state: uuidv4(),
    });
    const scope = 'name%20email';
    window.location.href = `https://appleid.apple.com/auth/authorize?${searchParams}&scope=${scope}`;
  }

  const appleLogin = (code: string) => {
    const body = {
      social_signin_type: 'apple',
      social_signin_access_token: code,
    };

    postData(`${baseUrl}/basicauth/socialSignIn`, body)
      .then(res => postLogin(res, true))
      .catch(console.log)
      .finally(() => dispatch(endLoading()));
  }

  useEffect(() => {
    const type = query.get('type');
    const apple_code = query.get('code');

    if (type === 'apple' && Boolean(apple_code)) {
      dispatch(startLoading());
      appleLogin(`${apple_code}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SignInComponent
    isLoading={isLoading}
    signUpRoute={SIGN_UP}
    signUpSearch=''
    forgotRoute={FORGOT_PASSWORD}
    forgotSearch=''
    handleSubmit={handleSubmit}
    handleContactUs={handleContactUs}
    googleLogin={googleLogin}
    handleFacebookLogin={handleFacebookLogin}
    handleLineLogin={handleLineLogin}
    handleAppleLogin={handleAppleLogin}
  />
}
