import React, { useEffect, useState, useRef } from 'react'
import PersonalInfo from "./personalInfo.component";
import userApi from '../../../../api/user';
import UserInfo from './userInfo';
import moment from 'moment'; 
import { v4 as uuidv4 } from 'uuid';
import {  useNavigate } from 'react-router-dom';
import FileHelper from '../../../../lib/helper/fileHelper'
import { openActionDialog } from '../../../../features/action-dialog';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading,endLoading } from '../../../../features/loading';
import { Info } from "../../../../lib/types/User";
import { openResponseDialog } from '../../../../features/response-dialog';
import { openErrorDialog } from '../../../../features/error-dialog';
interface Timezone {
  id: string;
  text: string;
  value: string;
}

const defaultTimezone: Timezone = {
  "id": "Asia/Kuala_Lumpur",
  "text": "(GMT+08:00) Kuala Lumpur",
  "value": "+08:00"
};

export default function PersonalInfoContainer(){

  const isConfirmed = useSelector<any>(state  => state.actionDialog.isConfirmed);
  const dialogAction = useSelector<any>(state  => state.actionDialog.action);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    uuid:  "",
    name:  "",
    email:  "",
    phoneNumber:  "",
    phoneCode: "+60",
    profileImage:  "",
    // address1: "",
    // address2: "",
    birthday: "",
    timezone: defaultTimezone.id
  })

  const [file, setFile] = React.useState<any>();
  const [requestBody, setRequestBody] = useState<any>();
  const [shippingRequestBody, setShippingRequestBody] = useState<any>();
  const [billingRequestBody, setBillingRequestBody] = useState<any>();
  const [responseDialogOpen, setResponseDialogOpen] = useState(false)
  const [errors] = useState<Error>({
    name: null,
    phone_number: null,
    birthday: null
  })
  const [hasError,setHasError] = useState(false)

  interface Error {
    name: string | null,
    phone_number: string | null,
    birthday: string | null
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useRef();

  async function getUrl(fullPath: string) {
    const response  = await FileHelper.getUrl(fullPath).then(repsonse => repsonse)
    return response
  }
  
  const handleSubmit = async (values:any) => {
    // console.log(event);
    // event.preventDefault();
    setHasError(false)
    // const data = new FormData(event.currentTarget);

    setRequestBody({
      name: userInfo?.name,
      birthday:  moment(userInfo?.birthday).format('YYYY-MM-DD'),
      phone_number: userInfo?.phoneNumber,
      timezone: userInfo?.timezone,
      phone_code: userInfo?.phoneCode,
      profile_image:  file ? await uploadFile('user', file) : undefined,
      // address_1: data.get('address1'),
      // address_2: data.get('address2')
    })

    setShippingRequestBody({
      address_1: values.shipping_address_1,
      address_2: values.shipping_address_2,
      city: values.shipping_city,
      country: values.shipping_country,
      state: values.shipping_state,
      zip: values.shipping_zip
    });

    setBillingRequestBody({
      address_1: values.billing_address_1,
      address_2: values.billing_address_2,
      city: values.billing_city,
      country: values.billing_country,
      state: values.billing_state,
      zip: values.billing_zip
    });

    await formValidate(requestBody);
    if(hasError) return

    dispatch(openActionDialog({
      title: "Save Changes?",
      description: "Save changes to the user settings?",
      action: "saveUserSettings"
    }))
  };
  const formValidate = async (requestBody) =>{
    // const validationErrors = errors;
    // if(!requestBody?.name){
    //   setHasError(true)
    //   validationErrors.name = "Incorrect name"
    // }
    // if(!requestBody.phone_number){
    //   setHasError(true)
    //   validationErrors.phone_number = "Incorrect phone number"
    // }

    // if(!requestBody.birthday){
    //   setHasError(true)
    //   validationErrors.birthday = "Incorrect birthday"
    // }
    // return true;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const inputName = e.target.name;
    // if (input === '') {
    //   errors.name = "Incorrect name" 
    //   return
    // } else {
    //   errors.name = "" 
    // }

    const result = {
      ...userInfo,
      [inputName]: input,
    }

    setUserInfo(result)
  };

  const handlePhoneChange = (phoneCode: string , phoneNumber: string ) => {
    
    if (phoneNumber === '') {
      errors.phone_number = "Incorrect phone number" 
    } else {
      errors.phone_number = "" 
    }
    
    setUserInfo({
      ...userInfo,
      phoneNumber: phoneNumber,
      phoneCode: phoneCode || userInfo.phoneCode
    });
    
  };

  const handleBirthdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    errors.birthday = "";
    if (e.target.value === '') {
      errors.birthday = "Incorrect birthday";
    } else {
      if (!isValidDate(e.target.value)) {
        errors.birthday = "Incorrect birthday"; 
      }
    }

   setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleTimezoneChange = (tzID: string) => {
    setUserInfo({
      ...userInfo,
      timezone: tzID,
    });
  };

  function isValidDate(dateString) {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!dateString.match(regEx)) return false;  // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    var currentDate = new Date(Date.now());
    if (d > currentDate) return false;
    if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0,10) === dateString;
  }

  function handleFileChange(e) {
    const fileSize = e.target.files[0].size;
    const fileSizeInMB = fileSize / (1024 * 1024);
    if(fileSizeInMB > 12){
      dispatch(openErrorDialog({ title: "File Size Exceeded", description: "File size should not exceed 25MB" }))
      return;
    }
    setFile(e.target.files[0]);
  }
  const getUser = () => JSON.parse(localStorage.getItem('user') ?? '{}');

  async function uploadFile(filePath: string, file) {
    
    let user = getUser();
    let fullPath = `user/${user.access_token_payload.uuid}/profiles/${filePath}/${uuidv4()}_${file.name}`;
  
    // Create presigned post
    const { url, fields } = await FileHelper.createUrl(fullPath).then(repsonse => repsonse)
  
    // Upload the file
    const formData = new FormData();
    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });
    formData.append("file", file);
    const res = await fetch(url, {
      method: "POST",
      body: formData,
    });
  
    // Get the URL if file uploaded successfully
    if (res.status === 204) {
      return fullPath;
      // return fetch('http://localhost:8889/pixelserver/get-presigned-url', {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ fileName: fullPath }),
      // })
      // .then(res => res.json())
      // .then(data => data.url)
    }
  }

  const handleDialogConfirm = async () =>{

    const shippingValues = {
      address_1: shippingRequestBody.address_1,
      address_2: shippingRequestBody.address_2,
      city: shippingRequestBody.city,
      country: shippingRequestBody.country,
      state: shippingRequestBody.state,
      zip: shippingRequestBody.zip
    }

    await userApi.updateShipping({requestBody:shippingValues}).then((res) => {
      if(res.success){
        // dispatch(openResponseDialog({title:'Success',message:'Billing address updated successfully'}));
      }else{
        dispatch(openResponseDialog({title:'Error',message:'Failed to update billing address'}));
      }
    }).catch((err) => {
      dispatch(openResponseDialog({title:'Error',message:'Failed to update billing address'}));
    }).finally(() => {
      dispatch(endLoading());
    });


    const billingValues = {
      address_1: billingRequestBody.address_1,
      address_2: billingRequestBody.address_2,
      city: billingRequestBody.city,
      country: billingRequestBody.country,
      state: billingRequestBody.state,
      zip: billingRequestBody.zip
    }

    await userApi.updateBilling({requestBody:billingValues}).then((res) => {
      if(res.success){
        // dispatch(openResponseDialog({title:'Success',description:'Billing address updated successfully'}));
      }else{
        dispatch(openResponseDialog({title:'Error',description:'Failed to update billing address'}));
      }
    }).catch((err) => {
      dispatch(openResponseDialog({title:'Error',description:'Failed to update billing address'}));
    }).finally(() => {
      dispatch(endLoading());
    });

    const response = await userApi.update({requestBody: requestBody})
    .then(response => response)
    .catch(error => error);
    
    if(response.success){
      let user = JSON.parse(localStorage.getItem('user') ?? '{}');
      user.access_token_payload.name = userInfo.name;
      localStorage.setItem("user", JSON.stringify(user));
      setResponseDialogOpen(true)
    }
  }

  const handleResponseClose = () =>{
    navigate(0)
  }
  
  const [initialInfo, setInitialInfo] = useState<Info>({
    name: '',
    phone_code: '',
    phone_number: '',
    timezone: '',
    birthday: '',

    shipping_address_1: '',
    shipping_address_2: '',
    shipping_city: '',
    shipping_state: undefined,
    shipping_zip: '',
    shipping_country: 'MY',
    billing_address_1: '',
    billing_address_2: '',
    billing_city: '',
    billing_state: undefined,
    billing_zip: '',
    billing_country: 'MY',
  });

  const getBillingAndShipping = async () => {
    dispatch(startLoading());
    let response = {
      ...initialInfo
    };

    await userApi.getShipping().then((res) => {
      if(res.success){
         response = {
          ...initialInfo,
          shipping_address_1: res.data.address_1,
          shipping_address_2: res.data.address_2,
          shipping_city: res.data.city,
          shipping_state: res.data.state,
          shipping_zip: res.data.zip,
          shipping_country: res.data.country
        }
      }
    }).catch((err) => {
    });

    await userApi.getBilling().then((res) => {
      if(res.success){
        response = {
          ...response,
          billing_address_1: res.data.address_1,
          billing_address_2: res.data.address_2,
          billing_city: res.data.city,
          billing_state: res.data.state,
          billing_zip: res.data.zip,
          billing_country: res.data.country,
        }
      }
    }).catch((err) => {
    }).finally(() => {
      dispatch(endLoading());
    });
    
    setInitialInfo(response);
  }

  useEffect(() =>{
    if(isConfirmed && dialogAction === "saveUserSettings") handleDialogConfirm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isConfirmed])
  
  useEffect(() =>{
    dispatch(startLoading())
    getBillingAndShipping();
    const getDetail = async () => {
      const response = await userApi.getDetail().then(response => response)
      .catch(error => error);
      if(!response.success){
        return [];
      }
      const data = response.data;
  
      let profileImage = '';
      if(data?.profile_image){
        profileImage = await getUrl(data.profile_image).then(repsonse => repsonse)
      }
    
      setUserInfo({
        uuid: data.uuid || "" ,
        name: data.name || "",
        email: data.email || "",
        phoneNumber: data.phone_number || "",
        phoneCode: data.phone_code || "+60",
        profileImage: profileImage || "",
        // address1: data.address_1 || "",
        // address2: data.address_2 || "",
        birthday:  moment(data.birthday).format('YYYY-MM-DD') || "",
        timezone: data.timezone || defaultTimezone.id,
      })
      return response.data
    }
    getDetail()
    dispatch(endLoading())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  

  return <PersonalInfo 
    userInfo={userInfo}
    phoneNumber={userInfo.phoneNumber}
    defaultTimezone={defaultTimezone}
    handleSubmit={handleSubmit}
    handleInputChange ={handleInputChange}
    handlePhoneChange={handlePhoneChange}
    handleBirthdayChange={handleBirthdayChange}
    handleTimezoneChange={handleTimezoneChange}
    handleFileChange={handleFileChange}
    handleResponseClose={handleResponseClose}
    responseDialogOpen={responseDialogOpen}
    file={file}
    form={form}
    formErrors={errors}
    initialInfo={initialInfo}
  />
}