import React, { useEffect } from "react";
import SubscriptionComponent from "./subscriptionPayment.component";
import { PAYMENT, PORTAL_SETTINGS, SETTINGS, SUBSCRIPTION, SUCCESS } from "../../../../lib/constants/routes";
import { setBreadcrumb } from "../../../../features/admin";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import subscriptionApi from "../../../../api/subscription";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SubscriptionPaymentContainer() {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handlePaymentPlan = () => {
  //   const torderid = '';
  //   const vorderid = '';
  //   const rcode = '';
  //   window.open('https://www.onlinepayment.com.my/MOLPay/pay/pixelready_Dev?plan_id=723'+
  //   '&rcode='+rcode+
  //   '&torderid='+torderid+
  //   '&vorderid='+vorderid
  //   ,"_self");
  // }

  const handlePaymentPlan = () => {
    subscriptionApi.subscribeLifestyle({ duration: Number(query.get('is_yearly')) === 1 ? 2 : 1 })
      .then(() => navigate(`/${PAYMENT}/${SUCCESS}/`));
  }

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "Settings", path: SETTINGS },
        { name: "Subscription", path: `${PORTAL_SETTINGS}/${SUBSCRIPTION}/` },
        { name: "Payment", path: null },
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SubscriptionComponent
    currency='USD'
    handleBack={() => navigate(`${PORTAL_SETTINGS}/${SUBSCRIPTION}/`)}
    plan={`Lifestyle Plan ${Number(query.get('is_yearly')) === 1 ? '(1 year)' : '(1 month)'}`}
    price={Number(query.get('is_yearly')) === 1 ? 19.20 : 2.00}
    handlePaymentPlan={handlePaymentPlan}
  />
}