import React, { useEffect } from "react";
import { Box, Typography, Collapse } from "@mui/material";
import icons from "../../../../../assets/icons";
import FileHelper from "../../../../../lib/helper/fileHelper";

function CardRow({ card, currency }) {
  const isZakeke = Boolean(card.design_id);

  return <Box sx={{
    display: "flex",
    gap: '2rem',
    "@container (max-width: 880px)": {
      gap: '1rem',
    },
    alignItems: 'center'
  }}>
    <Box sx={{
      width: '250px',
      height: '250px',
      border: '1px solid #A7A7A7',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    }}>
      <img
        alt='Card preview'
        src={card.front_thumbnail}
        style={{
          maxWidth: isZakeke ? '500px' : '90%',
          maxHeight: isZakeke ? 'none' : '90%',
          borderRadius: '8px',
          boxShadow: isZakeke ? 'none' : '0 0 15px rgba(0, 0, 0, 0.2)',
        }}
      />
    </Box>
    <Box>
      <Typography sx={{ fontSize: '20px' }}>{card.name}</Typography>
      <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{currency} {card.price}</Typography>
      {/* <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{currency}{card.price}</Typography> */}
      <Typography sx={{ color: '#667085' }}>Quantity: {card.quantity}</Typography>
    </Box>
  </Box>
}

export default function CardInfo({ order }) {
  const [expanded, setExpanded] = React.useState(false);
  const [products, setProducts] = React.useState<any[] | never[]>([]);

  const handleExpand = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    Promise.all(order.products.map(async (product) => ({
      ...product,
      front_thumbnail: product?.front_thumbnail.slice(0, 5) === 'https' ? product?.front_thumbnail : await FileHelper.getUrl(product?.front_thumbnail),
    }))).then(setProducts);
    // eslint-disable-next-line
  }, [])

  return products.length > 0 && <Box sx={{
    minWidth: '400px',
    padding: '0 2rem',
    "@container (max-width: 880px)": {
      padding: 0,
    },
  }}>
    {!expanded ?
      (<>
        <CardRow card={products[0]} currency={order?.currency} />
        {products.length > 1 && (
          <Box sx={{ pt: '1rem', display: "flex", justifyContent: "space-between", cursor: 'pointer' }} onClick={() => handleExpand()}>
            <Typography>View more <label style={{ color: "#A7A7A7" }}>({products.length - 1} item{products.length - 1 > 1 ? 's' : ''})</label></Typography>
            <img src={icons.arrow_right_icon} alt="expand_arrow" />
          </Box>
        )}
      </>) :
      (<Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ display: 'flex', flexDirection: "column", gap: "2rem" }}>
          {products.map((card, index) => <CardRow key={index} card={card} currency={order?.currency} />)}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", cursor: 'pointer' }} onClick={() => handleExpand()}>
          <img src={icons.arrow_right_round} alt="close-button" />
        </Box>
      </Collapse>)}
  </Box>
}