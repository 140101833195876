import React from 'react';
import { Box, IconButton } from "@mui/material";
import { BEHANCE, FACEBOOK, INSTAGRAM, LINE, LINKEDIN, PINTEREST, SKYPE, SNAPCHAT, TELEGRAM, TIKTOK, TWITTER, VIBER, WECHAT, YOUTUBE } from '../../../lib/constants/socialmediatypes';
import { useSelector } from 'react-redux';
import icons from '../../../assets/icons';

const getIcon = (iconName: string) => {
  if (iconName === FACEBOOK) return icons.facebook_02;
  if (iconName === INSTAGRAM) return icons.instagram;
  if (iconName === LINKEDIN) return icons.linkedin_02;
  if (iconName === TWITTER) return icons.twitter_x;
  if (iconName === LINE) return icons.line;
  if (iconName === SNAPCHAT) return icons.snapchat;
  if (iconName === SKYPE) return icons.skype;
  if (iconName === BEHANCE) return icons.behance;
  if (iconName === YOUTUBE) return icons.youtube;
  if (iconName === PINTEREST) return icons.pinterest;
  if (iconName === TELEGRAM) return icons.telegram;
  if (iconName === WECHAT) return icons.wechat;
  if (iconName === TIKTOK) return icons.tiktok;
  if (iconName === VIBER) return icons.viber;

  return icons.webpage_icon;
}

export default function SocialLinkComponent({ socialInfo }: { socialInfo: any }) {
  const profileTheme = useSelector((state: any) => state.landingPage.theme);

  return <Box sx={{
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    maxWidth: '425px',
    flexWrap: 'wrap',
    gap: '0.5rem',
  }}>
    {socialInfo.content.map((value, index) => {
      let link = value.info;
      if (value.category === WECHAT) link = `weixin://weixin.qq.com/r/${link}`;

      return Boolean(value.info) ? <IconButton key={index} onClick={() => window.open(link)} sx={{ bgcolor: profileTheme?.button?.primary_color ?? '#FECD20', width: '48px', height: '48px' }}>
        <div className='icon' style={{
          backgroundColor: profileTheme?.button?.primary_font_color ?? '#373736',
          mask: `url(${getIcon(value.category)})`,
          maskSize: 'cover',
          maskRepeat: 'no-repeat',
          WebkitMask: `url(${getIcon(value.category)})`,
          WebkitMaskSize: 'cover',
          WebkitMaskRepeat: 'no-repeat',
          width: '24px',
          height: '24px',
        }} />
      </IconButton> : <></>
    })}
  </Box>
}