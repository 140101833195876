const tiers = {
  'month':[  
  {
    id: 1,
    type: 'FREE_TIER',
    title: 'FREE',
    subtitle: 'free forever',
    is_recommended: false,
    price: '0',
    yearly_price: '0',
    user: '1 user',
    summary: 'Begin your Pixel Card journey with our free features.',
    desc_title: 'Includes:',
    description: [
      'Single profile card',
      'QR code inclusion',
      'Personalize profile: basic info, contact details, social links',
      'Unlimited scans/taps',
      'Direct saving to phone contacts',
      'Environmental impact tracking',
      'Basic profile analytics',
      'Contact management (up to 200)',
    ],
    buttonText: 'Get Started For Free',
    buttonVariant: 'outlined',
    background: 'linear-gradient(135deg, #FE2E00, #FFCD1C)',
  },
  {
    id: 2,
    type:'LIFESTYLE',
    title: 'Lifestyle',
    subtitle: 'billed monthly',
    is_recommended: true,
    price: '2.00',
    yearly_price: '1.60',
    user: '1 user',
    summary: 'Personalize, brand, and analyze your profile.',
    desc_title: 'Everything in Free Plan, plus:',
    description: [
      'Up to 3x profile card switching',
      'Voucher code creation',
      'Appointment management',
      'Advanced profile analytics',
      'Unlimited contact management',
      'Eligibility for points redemption (Coming Soon)',
    ],
    buttonText: 'Subscribe Now',
    buttonVariant: 'contained',
    background: 'linear-gradient(135deg, #00C3CF, #FFCD1C)',
  },
  {
    id: 4,
    type:'LIFESTYLE_TRIAL',
    title: 'Lifestyle',
    subtitle: 'billed monthly',
    is_recommended: true,
    price: '2.00',
    yearly_price: '1.60',
    user: '1 user',
    summary: 'Personalize, brand, and analyze your profile.',
    desc_title: 'Everything in Free Plan, plus:',
    description: [
      'Up to 3x profile card switching',
      'Voucher code creation',
      'Appointment management',
      'Advanced profile analytics',
      'Unlimited contact management',
      'Eligibility for points redemption (Coming Soon)',
    ],
    buttonText: 'Start 7 days free trial',
    buttonVariant: 'contained',
    background: 'linear-gradient(135deg, #00C3CF, #FFCD1C)',
  },
  {
    id: 5,
    type:'BUSINESS',
    title: 'Business',
    subtitle: 'reach out to our sales for more info',
    is_recommended: false,
    price: 'Coming Soon',
    yearly_price: 'Coming Soon',
    user: '5+ users',
    summary: 'Customized for your business needs.',
    desc_title: 'Everything in Lifestyle Plan, plus:',
    description: [
      'Up to 5x profile card switching',
      'Team contact management',
      'Customizable system',
      'Simplified employee information upload',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
    background: 'linear-gradient(135deg, #C200BA, #FFCD1C)',
  }
  ],
  'year':[
    {
      id: 1,
      type: 'FREE_TIER',
      title: 'FREE',
      subtitle: 'free forever',
      is_recommended: false,
      price: '0',
      yearly_price: '0',
      user: '1 user',
      summary: 'Begin your Pixel Card journey with our free features.',
      desc_title: 'Includes:',
      description: [
        'Single profile card',
        'QR code inclusion',
        'Personalize profile: basic info, contact details, social links',
        'Unlimited scans/taps',
        'Direct saving to phone contacts',
        'Environmental impact tracking',
        'Basic profile analytics',
        'Contact management (up to 200)',
      ],
      buttonText: 'Get Started For Free',
      buttonVariant: 'outlined',
      background: 'linear-gradient(135deg, #FE2E00, #FFCD1C)',
    },
    {
      id: 3,
      type:'LIFESTYLE',
      title: 'Lifestyle',
      subtitle: 'when paid annually',
      is_recommended: true,
      price: '2.00',
      yearly_price: '1.60',
      user: '1 user',
      summary: 'Personalize, brand, and analyze your profile.',
      desc_title: 'Everything in Free Plan, plus:',
      description: [
        'Up to 3x profile card switching',
        'Voucher code creation',
        'Appointment management',
        'Advanced profile analytics',
        'Unlimited contact management',
        'Eligibility for points redemption (Coming Soon)',
      ],
      buttonText: 'Subscribe Now',
      buttonVariant: 'contained',
      background: 'linear-gradient(135deg, #00C3CF, #FFCD1C)',
    },
    {
      id: 4,
      type:'LIFESTYLE_TRIAL',
      title: 'Lifestyle',
      subtitle: 'when paid annually',
      is_recommended: true,
      price: '2.00',
      yearly_price: '1.60',
      user: '1 user',
      summary: 'Personalize, brand, and analyze your profile.',
      desc_title: 'Everything in Free Plan, plus:',
      description: [
        'Up to 3x profile card switching',
        'Voucher code creation',
        'Appointment management',
        'Advanced profile analytics',
        'Unlimited contact management',
        'Eligibility for points redemption (Coming Soon)',
      ],
      buttonText: 'Start 7 days free trial',
      buttonVariant: 'contained',
      background: 'linear-gradient(135deg, #00C3CF, #FFCD1C)',
    },
    {
      id: 5,
      type:'BUSINESS',
      title: 'Business',
      subtitle: 'reach out to our sales for more info',
      is_recommended: false,
      price: 'Coming Soon',
      yearly_price: 'Coming Soon',
      user: '5+ users',
      summary: 'Customized for your business needs.',
      desc_title: 'Everything in Lifestyle Plan, plus:',
      description: [
        'Up to 5x profile card switching',
        'Team contact management',
        'Customizable system',
        'Simplified employee information upload',
      ],
      buttonText: 'Contact us',
      buttonVariant: 'outlined',
      background: 'linear-gradient(135deg, #C200BA, #FFCD1C)',
    }
  ]
};

export default tiers;