import React, { useEffect } from "react";
import Drawer from "../drawer";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
// import { googleLogout } from '@react-oauth/google';
import {
  Badge,
  Box,
  Container,
  CssBaseline
} from '@mui/material';
import ContentBreadcrumb from "../contentBreadcrumb";
import { isMobile,isTablet } from 'react-device-detect';
import userApi from '../../api/user'
import FileHelper from '../../lib/helper/fileHelper'
import icons from "src/assets/icons";
import { PORTAL_SETTINGS } from "src/lib/constants/routes";
import NotificationMenu from "../notification-menu";
import { useSelector } from "react-redux";
import CartDrawer from "../cart-drawer";
import authApi from "src/api/auth";
const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      width:0,
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#FECD20',
    },
    secondary: {
      main: '#373736',
      contrastText: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h1: {
      fontSize: '42px', 
      fontWeight: 400,
    },
    h2: {
      fontSize: '31px', 
    },
    h3: {
      fontSize: '20px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '16px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#FFF',
          backgroundColor: '#3C3C3B',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        // root: {
        //   fontSize: '13px',
        //   fontWeight: 500,
        // }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius:"30px"
        }
      },
    },
    MuiToolbar:{
      styleOverrides: {
        root: {
          justifyContent: 'space-between'
        }
      }
    },
  },
});

export default function AdminLayout() {
  // TODO: Temporary disable cart
  const cartItems =  useSelector((state: any) => state.cart.items);
  const [openCart, setOpenCart] = React.useState(false);
  const notifications = useSelector((state: any) => state.notifications.notifications);
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userImage,setUserImage] = React.useState("")
  const navigate = useNavigate();
  const location = useLocation();
  const setUserInfo = async () => {
    if (localStorage.getItem('user') !== null) { 
      const user = JSON.parse(localStorage.getItem('user') || "");
      if(user.access_token_payload){
        const response = await userApi.getDetail().then(response => response).catch(error => error)
        if(response.success){
          if(response.data.profile_image){
            const fileResponse  = await FileHelper.getUrl(response.data.profile_image).then(fileResponse => fileResponse)
            setUserImage(fileResponse)
          }
        }

      }
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  
  // verify access token whenever  
  const verifyToken = async () => {
    await authApi.verifyToken()
    .then((res) => {})
    .catch((err) => {
      if(err.code === 3){
        localStorage.removeItem("user");
        localStorage.setItem("contactGuide", 'false')
        navigate('/')
      }
    });
  }
  
  useEffect(() =>{
    setUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || "");
    if(user){
      verifyToken();
    }
    if(isMobile) setOpen(false)
    if(isTablet) setOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppBar position="absolute" open={open} elevation={0} style={{minHeight: '75px'}}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              pt: "1rem"
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <img src={icons.menu_burger.toString()} alt="menu burger"/>
            </IconButton>
            {/* Flex space between, need an empty box to setting button stick to right */}
            <Box><ContentBreadcrumb /></Box>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
              <IconButton color="inherit" onClick={handleClick}>
                <Badge badgeContent={notifications.filter((noti: any) => !noti.is_read).length} color="primary">
                  <div className='icon' style={{
                    backgroundColor: 'white',
                    mask: `url(${icons.notification})`,
                    maskSize: 'cover',
                    maskRepeat: 'no-repeat',
                    WebkitMask: `url(${icons.notification})`,
                    WebkitMaskSize: 'cover',
                    WebkitMaskRepeat: 'no-repeat',
                    height: '28px',
                    width: '28px',
                  }} />
                </Badge>
              </IconButton>
              <NotificationMenu anchorEl={anchorEl} handleClose={handleClose} />
              {/* TODO: Temporary disable cart */}
              <IconButton color="inherit"  onClick={() => 
                {
                  // TODO : temporary disable cart
                  setOpenCart(!openCart)
                }}>
                <Badge badgeContent={cartItems?.length} color="primary">
                  <div className='icon' style={{ 
                    backgroundColor: 'white',
                    mask: `url(${icons.shopping_cart})`,
                    maskSize: 'cover',
                    maskRepeat: 'no-repeat',
                    WebkitMask: `url(${icons.shopping_cart})`,
                    WebkitMaskSize: 'cover',
                    WebkitMaskRepeat: 'no-repeat',
                    height: '28px',
                    width: '28px',
                  }}/>
                </Badge>
              </IconButton>
              <Box 
                onClick={() => navigate(PORTAL_SETTINGS)}
                style={{ 
                  borderRadius: "50%", width:"45px", height:"45px", cursor: "pointer",
                  backgroundImage: `url(${userImage || icons.profile_picture_icon.toString()})`,backgroundSize: "cover",backgroundPosition: "center"
                }
                }/>
            </Box>
            {/* <Button style={{
              width:"unset",
              height: "50px",
              backgroundColor: "#FECD20",
              color:'#000000'
            }}
            onClick={() => navigate(PORTAL_SETTINGS)}>
              <Box style={{
                    display: 'grid',
                    alignItems: 'center',
                    gridTemplateColumns: '1fr 4fr',
                    gap: '1rem'
              }}>
              <img src={userImage || images.empty_avatar}
              style={{borderRadius: "50%", width:"40px",height:"40px"}}/>
              <Box style={{display:'flex',justifyContent: 'flex-start'}} >
              <Typography style={{fontSize:"13px",width:"max-content",overflow:"hidden"}}>
              {username}
              </Typography>
              </Box>

              </Box>
            </Button> */}
          </Toolbar>
        </AppBar>
        <Drawer toggleDrawer={toggleDrawer} open={open}/>
        <Box
          component="main"
          sx={{
            backgroundColor:'#F9FAFC',
            // backgroundColor: (theme) =>
            //   theme.palette.mode === 'light'
            //     ? 'white'
            //     : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            
          }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 4, mb: 4}}>
            <Outlet/>
          </Container>
        </Box>
      </Box>
      {/* TODO: temporary close cart module */}
      <CartDrawer open={openCart} setOpen={setOpenCart} />
    </ThemeProvider>
  );
}
