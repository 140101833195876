import { Typography, Divider, ListItem, Button, Menu, MenuItem, ListItemText, Box } from '@mui/material';
import icons from "src/assets/icons";
import ReactTimeAgo from 'react-time-ago';
import moment from 'moment';

interface NotificationMenuComponentProps {
  notifications: any[];
  anchorEl: any;
  handleClose: () => void;
  handleMarkAsRead: () => void;
  handleViewAll: () => void;
  handleAction: (path: string) => void;
}

export default function NotificationMenuComponent({
  notifications,
  anchorEl,
  handleClose,
  handleMarkAsRead,
  handleViewAll,
  handleAction,
}: NotificationMenuComponentProps) {
  const remaining_unread = notifications.slice(5).filter(noti => !noti.is_read).length;

  return <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleClose}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
      style: { minWidth: '390px' },
    }}
  >
    <ListItem alignItems="center" sx={{ justifyContent: 'space-between' }}>
      <Typography sx={{ fontWeight: 500 }}>Notifications</Typography>
      {Boolean(notifications.length) && <Button
        startIcon={<img src={icons.double_tick.toString()} alt="" />}
        sx={{ fontWeight: 400, color: 'text.primary' }}
        onClick={handleMarkAsRead}
      >
        Mark all as read
      </Button>}
    </ListItem>
    {Boolean(notifications.length) && notifications.slice(0, 5).map(noti => <MenuItem
      key={noti.uuid}
      onClick={() => handleAction(noti.action)}
      sx={{ bgcolor: noti.is_read ? undefined : '#FFF8E0' }}
    >
      <div style={{
        width: '8px',
        height: '8px',
        background: noti.is_read ? undefined : '#EF1D44',
        borderRadius: '50%',
      }} />
      <Box sx={{ p: '8px 16px', alignItems: 'flex-start', flex: 1 }}>
        <ListItemText
          disableTypography
          primary={<Typography sx={{ fontWeight: 500 }}>
            {noti.title}
          </Typography>}
          secondary={
            <>
              <Typography variant="body2" color="text.primary">
                {noti.description}
              </Typography>
              <Typography variant="body2" color='#858585'>
                <ReactTimeAgo date={moment(noti.timestamp, 'YYYY-MM-DD HH:mm:ss').toDate()} locale="en-US" />
              </Typography>
            </>
          }
        />
      </Box>
    </MenuItem>)}
    {!Boolean(notifications.length) && <Box sx={{ color: '#C5C5C5', p: '16px 32px', textAlign: 'center' }}>
      No notifications
    </Box>}
    {Boolean(notifications.length) && <Divider />}
    {Boolean(notifications.length) && <ListItem alignItems="flex-start">
      <Button
        sx={{ fontWeight: 500, color: '#4E8AE4' }}
        onClick={handleViewAll}
      >
        View all {Boolean(remaining_unread) && `(${remaining_unread})`}
      </Button>
    </ListItem>}
  </Menu>
};