
export const PORTAL = "/portal";
export const SETTINGS = "settings";
export const DASHBOARD = 'dashboard';
export const PROFILE = 'profile';
export const PROFILE_LIST = 'profile-list';
export const UPDATE_PROFILE = 'profile/:profileUuid/update';
export const CONTACTS = 'contacts';
export const CONTACT_DETAIL = 'contact/:contactUUID/details'
export const APPOINTMENT = 'appointment';
export const CAMPAIGNS = 'campaigns';
export const CAMPAIGN = 'campaign';
export const NOTIFICATIONS = 'notifications';
export const VOUCHER  = `voucher`;
export const VOUCHER_CREATE = 'voucher/create';
export const VOUCHER_CODE = 'voucherCode';
export const VOUCHER_CODE_CREATE = 'create';
export const VOUCHER_EDIT = 'edit';
export const VOUCHER_DETAIL = 'voucher/:voucherUUID';
export const CARDS = 'cards';
export const BUY = 'buy';
export const TEMPLATES = 'templates';
export const EDIT = 'edit';
export const CART = 'cart';
export const WALLET = 'wallet';
export const PAYMENT = 'payment';
export const SUCCESS = 'success';
export const FAIL = 'fail';
export const PENDING = 'pending';
export const WALLET_REDEEM_VOUCHER = 'redeemVoucher';
export const WALLET_VALIDATE_VOUCHER = 'validate';
export const WALLET_VALIDATE_SUCCESS = 'success';
export const ZAKEKE_CUSTOMIZER = 'customizer';
export const SUBSCRIPTION = 'subscription';
export const CONTACT = 'contact';

export const PORTAL_SETTINGS = `${PORTAL}/${SETTINGS}`;
export const PORTAL_DASHBOARD = `${PORTAL}/${DASHBOARD}`;
export const PORTAL_PROFILE = `${PORTAL}/${PROFILE}`;
export const PORTAL_PROFILE_LIST = `${PORTAL}/${PROFILE_LIST}`;
export const PORTAL_UPDATE_PROFILE = `${PORTAL}/${UPDATE_PROFILE}`;
export const PORTAL_CONTACTS = `${PORTAL}/${CONTACTS}`;
export const PORTAL_CONTACT_DETAIL = `${PORTAL}/${CONTACT_DETAIL}`;
export const PORTAL_APPOINTMENT = `${PORTAL}/${APPOINTMENT}`;
export const PORTAL_CAMPAIGNS = `${PORTAL}/${CAMPAIGNS}`;
export const PORTAL_CAMPAIGN_DETAIL = `${PORTAL}/${CAMPAIGN}/:id`;
export const PORTAL_VOUCHER = `${PORTAL}/${CAMPAIGN}/:id/${VOUCHER}`;
export const PORTAL_VOUCHER_CREATE = `${PORTAL}/${CAMPAIGN}/:id/${VOUCHER_CREATE}`;
export const PORTAL_VOUCHER_CODE = `${PORTAL}/${CAMPAIGN}/:id/${VOUCHER_CODE}/:voucherUUID`;
export const PORTAL_VOUCHER_CODE_CREATE = `${PORTAL}/${CAMPAIGN}/:id/${VOUCHER_CODE}/:voucherUUID/${VOUCHER_CODE_CREATE}`;
export const PORTAL_CARDS = `${PORTAL}/${CARDS}`;
export const PORTAL_CARDS_BUY = `${PORTAL}/${CARDS}/${BUY}`;
export const PORTAL_WALLET = `${PORTAL}/${WALLET}`;
export const PORTAL_WALLET_REDEEM_VOUCHER = `${PORTAL}/${WALLET}/:userVoucherUUID/${WALLET_REDEEM_VOUCHER}`; 
export const PORTAL_WALLET_VALIDATE_VOUCHER = `${PORTAL}/${WALLET}/:userVoucherUUID/${WALLET_VALIDATE_VOUCHER}`;

export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';

export const GUEST_SIGN_UP = '/guest-signup';
export const PERSONAL_INFO = '/personalinfo';
export const PRICING = '/pricing';
export const COMPLETED = '/completed';
export const EXPIRED = '/expired';
export const FORGOT_PASSWORD = '/forgotPassword';
export const CHECK_INBOX = '/checkInbox';
export const RESET_PASSWORD = '/resetPassword';
export const RESET_PASSWORD_SUCCESS = '/resetPassword/success';
export const RESET_PASSWORD_EXPIRED = '/resetPassword/expired';
export const UNVERIFIED = '/unverified';
export const ERROR = '/error';

// LANDING PAGE 
export const OLD_LANDING_PAGE = '/landingPage/:id';
export const OLD_LANDING_PAGE_ERROR = OLD_LANDING_PAGE+'/error';
export const LANDING_PAGE = '/w';
export const LANDING_PAGE_FROM_CARD = '/c';
export const LANDING_PAGE_ID = ':id';
export const SHARE_QR = 'share-qr';
export const EXCHANGE_CONTACT = 'exchange-contact';
export const BOOK_APPOINTMENT = 'book-appointment';
export const EXCHANGE_CONTACT_CONNECTED = 'exchange-contact/connected';
export const EXCHANGE_CONTACT_MEMBER_CONNECTED = 'exchange-contact/memberConnected';
export const EXCHANGE_CONTACT_EXPIRED = 'exchange-contact/expired';
export const BOOK_APPOINTMENT_SUMMARY = 'summary';
export const BOOK_APPOINTMENT_CONFIRMED = 'confirmed';

// export const LANDING_PAGE_MAIN = `${LANDING_PAGE}/${LANDING_PAGE_ID}`;
// export const LANDING_PAGE_SHARE_QR = `${LANDING_PAGE}/${LANDING_PAGE_ID}/${SHARE_QR}`;
// export const LANDING_PAGE_EXCHANGE_CONTACT = `${LANDING_PAGE}/${LANDING_PAGE_ID}/${EXCHANGE_CONTACT}`;
// export const LANDING_PAGE_MEMBER_CONNECTED = `${LANDING_PAGE}/${LANDING_PAGE_ID}/${EXCHANGE_CONTACT_MEMBER_CONNECTED}`; 
// export const LANDING_PAGE_EXPIRED = `${LANDING_PAGE}/${LANDING_PAGE_ID}/${EXCHANGE_CONTACT_EXPIRED}`;
// LANDING PAGE END

// EXCHANGE CONTACT
export const EXCHANGE_CONTACT_VERIFY_COMPLETED = '/exchange-contact/completed';
export const EXCHANGE_CONTACT_MEMBER_SIGN_IN = '/exchange-contact/member-signin';
export const EXCHANGE_CONTACT_GUEST_SIGN_UP = '/exchange-contact/guest-signup';
export const EXCHANGE_CONTACT_PERSONAL_INFO = '/exchange-contact/personal-info';
export const EXCHANGE_CONTACT_PROFILE_LIST = '/exchange-contact/profile-list';
// EXCHANGE CONTACT END

// COLLECT VOUCHER
export const COLLECT_VOUCHER_CONNECTED = 'collect/connected';
export const COLLECT_VOUCHER_MEMBER_SIGN_IN = 'collect/member-signin';
export const COLLECT_VOUCHER_GUEST_SIGN_IN = 'collect/guest-signin';
export const COLLECT_VOUCHER_GUEST_SIGN_UP = 'collect/guest-signup';
export const COLLECT_VOUCHER_PROFILE_LIST = 'collect/profile-list';